<template>
  <div>
    <prices-filter @update="getPrices" />
    <prices-table catalog-type="local" :loading="loading" />
  </div>
</template>
<script>
import PricesFilter from "@/components/prices/PricesFilter"
import PricesTable from "@/components/prices/PricesTable"

export default {
  name: "LocalPrices",
  components: {
    PricesFilter,
    PricesTable,
  },
  data() {
    return {
      loading: false,
    }
  },
  async mounted() {
    await this.getPrices()
  },
  methods: {
    async getPrices() {
      this.loading = true
      await this.$store.dispatch("prices/fetchPrices", "local")
      this.loading = false
    },
  },
}
</script>

<template>
  <div class="clearfix">
    <div class="top-buttons">
      <a-space>
        <a-select
          v-if="catalogType === 'client'"
          show-search
          placeholder="Выберите клиента"
          :filter-option="true"
          required
          :options="clients"
          @change="selectorChange"
          option-filter-prop="children"
          style="width: 200px"
          :defaultActiveFirstOption="true"
          :disabled="loading"
        />
        <a-upload
          :file-list="fileList"
          :show-upload-list="false"
          :remove="handleRemove"
          :before-upload="beforeUpload"
          @change="handleUpload"
        >
          <a-button v-if="isDealer" type="primary">
            <a-icon type="upload" />
            {{
              loading ? "Загружается..." : "Загрузить прайс-лист (.xls, .xlsx)"
            }}
          </a-button>
          <a-button v-if="isOwner" type="primary">
            <a-icon type="upload" />
            {{ loading ? "Загружается..." : "Загрузить наличие (.xls, .xlsx)" }}
          </a-button>
        </a-upload>
        <a-button
          v-if="isDealer"
          type="link"
          @click="downloadTemplate('Шаблон прайса.xlsx')"
        >
          Выгрузка шаблона
        </a-button>
        <a-button
          v-if="isOwner"
          type="link"
          @click="downloadTemplate('Шаблон поставщика.xlsx')"
        >
          Шаблон
        </a-button>
      </a-space>
    </div>
    <div v-if="success" class="sentinfo">
      <a-result
        status="success"
        title="Файл успешно загружен!"
        sub-title="Загруженные позиции появятся в каталоге"
      ></a-result>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import { message } from "ant-design-vue"

export default {
  name: "PricesUpload",
  props: {
    catalogType: {
      type: String,
      default: () => "local",
    },
    clients: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      fileList: [],
      loading: false,
      response: null,
      clientId: null,
      success: false,
    }
  },
  computed: {
    ...mapGetters({
      isDealer: "auth/isDealer",
      isOwner: "auth/isOwner",
    }),
  },
  methods: {
    downloadTemplate(file) {
      document.location = `/templates/${file}`
    },
    selectorChange(value) {
      this.clientId = value
    },
    handleRemove(file) {
      const index = this.fileList.indexOf(file)
      const newFileList = this.fileList.slice()
      newFileList.splice(index, 1)
      this.fileList = newFileList
    },
    beforeUpload(file) {
      this.fileList = [...this.fileList, file]
      return false
    },
    async handleUpload() {
      if (this.catalogType == "client" && !this.clientId) {
        this.$message.error("Сначала выберите клиента")
        return
      }
      const { fileList } = this
      const formData = new FormData()
      let checkExtension = null

      fileList.forEach(file => {
        checkExtension =
          file.name.includes(".xls") || file.name.includes(".xlsx")
        formData.append("file", file)
        if (checkExtension) formData.append("file", file)
        if (this.catalogType === "client" && checkExtension) {
          formData.append("client_id", this.clientId)
          formData.append("queue", 0)
        }
      })
      if (checkExtension) {
        this.success = false
        this.loading = true
        this.response = await this.$store.dispatch(
          "prices/uploadPrice",
          formData
        )
        if (this.response && this.response.data) {
          this.uploading = false
          if (this.response.data.success) {
            this.success = true
            this.$emit("update")
          }
          if (this.response.data.error) {
            message.error(this.response.data.error)
          }
        }

        this.loading = false
      } else this.$message.error("Загрузите файл с расширением (.xls, .xlsx)")
    },
  },
}
</script>

<style>
.top-buttons {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 15px 0 -10px;
}
.sentinfo {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
</style>

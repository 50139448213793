<template>
  <div class="content-container">
    <a-table
      bordered
      :columns="columns"
      :loading="loading"
      :data-source="prices"
      @change="handleChange"
      :pagination="{ defaultPageSize: 10, hideOnSinglePage: true }"
      :scroll="{ x: 1500 }"
      :customRow="customRow"
      class="ordertable"
      :rowKey="props => props.id"
    >
      <template slot="createdTime" slot-scope="item">
        <span>{{ displayDate(item) }}</span>
      </template>
      <a
        v-if="!isOwner"
        class="download"
        slot="download"
        slot-scope="text, record"
      >
        <a-button @click="downloadbtnClick(record)">
          <a-icon type="upload" class="downloadIcon" />
        </a-button>
      </a>
    </a-table>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import api from "@/utils/api"
import { message } from "ant-design-vue"

export default {
  props: {
    catalogType: {
      type: String,
      default: () => "local",
    },
    loading: {
      type: Boolean,
      default: () => false,
    },
  },
  computed: {
    ...mapGetters({
      prices: "prices/prices",
      isOwner: "auth/isOwner",
      isDealer: "auth/isDealer",
    }),
    isLocal() {
      return this.catalogType === "local"
    },
    columns() {
      const cols = [
        {
          title: "Наименование",
          dataIndex: "name",
          scopedSlots: { customRender: "name" },
        },
        {
          title: "Дата загрузки",
          width: 150,
          dataIndex: "createdAt",
          scopedSlots: { customRender: "createdTime" },
        },
      ]
      if (!this.isOwner) {
        cols.push({
          title: "Выгрузить",
          width: 150,
          key: "download",
          dataIndex: "download",
          scopedSlots: { customRender: "download" },
        })
      }
      console.log("CAT", this.catalogType)
      if (this.catalogType == "client") {
        cols.unshift({
          title: "Клиент",
          dataIndex: "client",
        })
      }
      return cols
    },
  },
  name: "PricesTable",
  mounted() {
    //this.fetchPrices(this.catalogType)
  },
  data() {
    return {
      popupConfirmIsVisible: false,
    }
  },
  methods: {
    ...mapActions({
      deletePrice: "prices/deletePrice",
      fetchPrices: "prices/fetchPrices",
    }),
    displayDate(value) {
      const createdAt = new Date(value)
      const day =
        createdAt.getUTCDate() < 10
          ? `0${createdAt.getUTCDate()}`
          : createdAt.getUTCDate()
      const month =
        createdAt.getUTCMonth() < 10
          ? `0${createdAt.getUTCMonth() + 1}`
          : createdAt.getUTCMonth() + 1
      return `${day}.${month}.${createdAt.getUTCFullYear()}`
    },
    handleChange(info) {
      const status = info.file.status
      if (status !== "uploading") {
        console.log(info.file, info.fileList)
      }
      if (status === "done") {
        this.$message.success(`${info.file.name} file uploaded successfully.`)
      } else if (status === "error") {
        this.$message.error(`${info.file.name} file upload failed.`)
      }
    },
    async downloadbtnClick(item) {
      if (!item.file) {
        message.error("Файл не существует!")
        return
      }
      await api.downloadFileFromGet(`/download/files/${item.file.uuid}`)
    },
    onDelete(id) {
      console.log("delete", id)
      if (id || id === 0) {
        this.deletePrice(id)
      }
    },
    customRow(record) {
      return {
        on: {
          click: () => {
            setTimeout(() => {
              if (
                !this.popupConfirmIsVisible &&
                !this.isDealer &&
                !this.isOwner
              ) {
                this.$router.push(`localprices/${record.id}`)
              }
            }, 300)
          },
        },
      }
    },
    visibleChange(visible) {
      this.popupConfirmIsVisible = visible
    },
  },
}
</script>

<style scoped lang="scss">
.ordertable {
  ::v-deep .ant-table-tbody > tr {
    cursor: pointer;
  }

  .action {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.downloadIcon {
  transform: rotate(90deg);
}
.ant-table-thead > tr > th {
  text-align: center !important;
}
</style>

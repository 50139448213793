<template>
  <div>
    <!--  <div class="content-container">
        <a-row class="filterFields" v-if="!isOwner">
          <a-col :span="24">
            <a-row :gutter="24">
              <a-col :span="8">
                <a-form layout="horizontal">
                  <a-form-item label="Статус">
                    <a-select
                      placeholder="Статус"
                      @change="handleChange"
                      class="selects"
                    >
                      <a-select-option value="Рабочий">Рабочий</a-select-option>
                    </a-select>
                  </a-form-item>
                </a-form>
              </a-col>
              <a-col :span="8">
                <a-form layout="horizontal">
                  <a-form-item label="Срок, дней">
                    <a-input @change="onChange" />
                  </a-form-item>
                </a-form>
              </a-col>
              <a-col :span="8">
                <a-form layout="horizontal">
                  <a-form-item label="Метод загрузки">
                    <a-select
                      placeholder="Статус"
                      @change="handleChange"
                      class="selects"
                    >
                      <a-select-option value="Сервер">Сервер</a-select-option>
                      <a-select-option value="Вручную">Вручную</a-select-option>
                      <a-select-option value="Эл. адрес">
                        Эл. адрес
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                </a-form>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="24" :offset="0" class="buttons">
                <a-button type="primary">Фильтр</a-button>
                <a-button>Очистить</a-button>
              </a-col>
            </a-row>
            <a-row>
              <a-form-item label="Период" class="filter-form-items">
                <a-range-picker class="reg-select selects" v-model="value3" :value="value3"/>
              </a-form-item>
              <a-col :span="24" :offset="0" class="buttons">
                <a-button type="primary">Сохранить</a-button>
              </a-col>
            </a-row>
          </a-col>
        </a-row>
    </div> -->
    <a-row>
      <prices-upload style="margin-top: 25px" @update="$emit('update')" />
    </a-row>
  </div>
</template>

<script>
import PricesUpload from "@/components/prices/PricesUpload"
import { mapGetters } from "vuex"

export default {
  components: { PricesUpload },
  name: "PriceFilter",
  data() {
    return {
      value3: [null, null],
    }
  },
  methods: {
    onChange() {
      console.log("Значение изменилось")
    },
    handleChange(info) {
      const status = info.file.status
      if (status !== "uploading") {
        console.log(info.file, info.fileList)
      }
      if (status === "done") {
        this.$message.success(`${info.file.name} file uploaded successfully.`)
      } else if (status === "error") {
        this.$message.error(`${info.file.name} file upload failed.`)
      }
    },
  },
  computed: {
    ...mapGetters({
      isOwner: "auth/isOwner",
    }),
  },
}
</script>
<style lang="scss" scoped>
.ml-10 {
  margin-left: 10px;
}
.buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 15px;
  > button {
    &:not(:first-child) {
      margin-left: 15px;
    }
  }
}
</style>
